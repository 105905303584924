//fonts

$font_0: Lato;
$font_1: sans-serif;
$font-themify: themify;
$font-awesome: FontAwesome;
$assetspath: "/assets/images";
// $primary-color: #AD342A;
$primary-color: #e7762d;
$font-color1: #4b4b4b;
$price-color: #CB6322;
$color-red:#ff4c3b;
$dark-font: #222222;
$grey3: #efefef;
$grey-font: #938d8c;
$font-color:#333333;
$header-font: #999999;
$theme-bg: #F8F9FA;
$dark-color: #404B59;
$dark-color-border: #929FB0;
$textgray:#9B9898;

