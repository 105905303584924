.inq-foot-links-st{
    .pointer {cursor: pointer;}
    .pointer:hover{
        font-weight: bold;
    }
    a{
        color:$white !important
    }
}

