.relatedproducts {
    padding:40px 0px 40px 0px;
    .title {
        padding:15px 0px 30px 0px;
    font-size: 14px;

    }
}

.zipcode{
input{
    box-sizing: border-box;
    border: 2px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }
  input:focus {
    border: 2px solid $primary;
  }
}
#prodName{
    font-size: 1.8rem;
    color:$primary;
}
#breadCrumbProductDetails{
    font-size: 0.8rem;
    color:rgb(80,79,79)
}
.normalSize{
    font-size: 14px;
}
.titleProduct{
    margin-bottom: 2px !important;
    font-size: 1.8rem;
    
}
.img-fluid-productDetail {
    height: 450px;
    width: 600px !important;
    padding: 0px 80px 0px 80px;
    padding-bottom: 2%;
   
}
#brokenImage{
    display: none;
}
.titleCase-product{
    margin-bottom: 15px !important;
  
}
.breadcrumb-productTitle{
    margin-bottom: 5px !important;
}
.priceRange{
    margin-top: 10px !important; 
    color: rgb(32, 32, 32);
}
.cartButton{
    background-color: #BDC62B !important;
     border: none !important;
}
.cartButton_bb1{
    background-color: #BDC62B !important;
     border: none !important;
}
.mayancartButton{
    background-color: #ffffff !important;
     border: none !important;
}
@media screen and (max-width: 768px){
    .mayancartButton{
        background-color: #ffffff !important;
        
         position: relative;
        
         border: 3px solid #3c707c !important;
         top:10PX;
    }
}
.shubhcardscartButton{
    background-color: #150985  !important;
    border: 1px solid #CC5500 !important;
}

.note{
    font-size: 0.8rem; 
    color:#777
}
.addSize{
    font-weight: bold;
}
.product-banner{
    margin-left: 25%;
    margin-right: 25%;
}
#product_breadcrumbs{
    padding-top: 8px;
    padding-left: 25px;
    font-size: 14px;
}

.img-fluid-product-thumb {
    height: 50px;
}

.product-video{
    width: 612px;
    height: 350px;
}
.wishlist-button{
    border:none !important;
}
.owl-prev{
    padding-right: 25%;
    padding-left: 25%;
}

.slider-nav {
    width: 100px;
    position: absolute;
    bottom: 15px!important;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    
.owl-thumb {
    img{
        opacity: 0.5;
    }
    background-color: $slogan-color;    
    cursor: pointer;
    &:hover {
        background-color: transparent;
        border:2px solid $secondary;
        img {
            opacity: 1;
        }
    }
    &.active {
        
        border:2px solid $secondary;
        background-color: transparent;
        img {
            opacity: 1;
        }
    }
}
}


.product-details-wrapper .details-wrapper .product-all-details .row .col-right .add-to-cart {
    margin-bottom: 30px;
    font-size: 14px;
}

.slider-nav .owl-thumb.active {
    border: 4px solid $pallet2 !important;
    background-color: transparent;
}


@media only screen and (max-width:767px){
    .product-banner{
        margin-left: 0%;
        margin-right: 0%;
    }
}

ul {
    &.one-column{
		columns: 1;
		-webkit-columns: 1;
		-moz-columns: 1;
		li{
			font-size: 14px;
			.list-label {
				color: $black;
				margin-right: 15px;
				min-width: 105px;
			}
			.list-value {
				color:$pallet2;
			}
		}
	}
	&.two-column{
		columns: 2;
		-webkit-columns: 2;
		-moz-columns: 2;
		li{
			font-size: 14px;
			.list-label {
				color: $light-ash;
				margin-right: 15px;
				min-width: 105px;
			}
			.list-value {
				color:$red;
			}
		}
	}
	&.three-column{
		columns: 3;
		-webkit-columns: 3;
		-moz-columns: 3;
		li{
			font-size: 14px;
			.list-label {
				color: $light-ash;
				min-width: 105px;
			}
			.list-value {
				color:$red;
			}
		}
	
	}
  }

.product-details-wrapper {
    .details-top {
        padding: 15px 0px 15px 0px;
    }
    .slider-nav {
        max-width: 400px;
        min-width: 300px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        
    .owl-thumb {
        img{
            opacity: 0.5;
        }
        background-color: #000;    
        cursor: pointer;
        &:hover {
            background-color: transparent;
            border:2px solid $secondary;
            img {
                opacity: 1;
            }
        }
        &.active {
            
            border:2px solid $secondary;
            background-color: transparent;
            img {
                opacity: 1;
            }
        }
    }
    }
    .product-all-details {
        .product-name {
            font-size: 30px;
        }
    }

    .details-wrapper {
        .product-all-details {
            background-color: $white;
            padding: 0px 15px 15px 15px;
            .price {
				font-size: 28px;
			}
            .row {
                padding:15px;
                .col-left {
                    flex: 1;
                    padding-right: 15px;
                }
                .col-right{
                    min-width: 250px;
                     .add-to-cart {
                        margin-bottom: 30px;
                    }
                    
          
                    .delivery-option {
                        margin-top: 30px;
                        h6 {
                            .fa {
                                    font-size: 22px;
                                    -webkit-transform: scaleX(-1);
                                    transform: scaleX(-1);
                            }
						}
						.input-group {
							input {
								border-right:0px;
							}
							.input-group-text {
								background-color: $white;
								border-left:0;
								color: blue;
							}
						}
						
                    }
                   
                }
                .bullet-points {
                    ul {
                        li {
                            list-style: circle !important;
                        }
                    }
                }
            }
            
            .description{
                padding-bottom: 15px;
            }
            .product-addtional-details {
                position: relative;
                padding-top: 15px;
                &::before {
                    content: "";
                    position: absolute;
                    width: calc(100% - 198px);
                    height: 2px;
                    background: #707070;
                    opacity: 0.2;
                    box-shadow: 0;
                    top: -10px;
                }
            }
        }
        
    }
    
}

.product-image-container{
    height: 100px;
    width: 180px;
    border: 1px dotted $primary;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.overlay {
    position: absolute;
    right: -10px;
    top:-10px;
    // z-index: 5;
  }
.sizeText{
    font-weight: bold;
    text-transform: uppercase !important;
    
}

.image-preview-product.active{
    border:2px solid $primary !important
}
.sizebox{
    border-style:none!important;
    background-color: lightgrey !important;
    color:$black !important;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
}


.sizebox.active
{
    border-style:none!important;
    color: $white !important;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
    background-color:$primary !important;
    font-weight:bold;
    
}
.price {
    font-size:xx-large;
    font-weight: bold;
   
}
.viewCart{
    background-color:$primary !important;
    color:$white !important;
    border:none !important
}
.bombayViewCart{
    background-color:$primary !important;
    color:$white !important;
    border:none !important;
    width: 65%;
    margin-top: 2%;
    margin-left: -4% !important;
}
.bombayViewCart-details{
    background-color:$primary !important;
    color:$white !important;
    border:none !important
}
.DeeraviewCart{
    background-color:$primary !important;
    color:$white !important;
    border:none !important
}
@media  screen and (max-width: 600px) {
    .viewCart {
        width: 100%!important;
        //padding-left: 10px;
        position: relative;
        margin-left: 0px !important;
        margin-left: 0!important;
    }
    .bombayViewCart{
        width: 70%!important;
        position: relative;
        left: -5%;
        transform: scale(0.9);
    }
    .bombayViewCart-details{
        position: relative;
        left: -1%;
    }
    .DetailsviewCart {
        width: 50%!important;
        //padding-left: 10px;
        position: relative;
    }
    .DeeraviewCart {
        position: relative;
    }
  }

.mayanviewCart{
    background-color:#000000 !important;
    color:$white !important;
    border:none !important
}
.rotate-dot{
	.owl-theme {
		.owl-dots {
		
			transform: translateX(-50%);
			[class*="owl-"] {
				width: 25px;
				height: 25px;
				transform: rotate(130deg);
				margin: 0px 6px;
				border: 1px solid transparent;
				&.active {
					border: 1px solid blue;
					span {
						background-color: blue;
					}
				}

				span {
					width: 12px;
					height: 12px;
					border-radius: 0;
					margin: 5px 5px;
				}
			}
		}
	}
}

#size{
    padding-left: 50px;
}
.productDetailImage{
    background: #fff;
    padding-bottom: 15px;
    margin-bottom: 10px;
}


.product-box-inner {
    display:flex;
    padding:20px;
    border-bottom:2px solid #dddddd; 
    position: relative;   
       
    .product-detail {
        padding-left: 30px;
        position: relative;
        .sku {           
                font-size: 16px;
                color: #a2a0a0dd;
        }
        
        .add-to {
            position: absolute;
            bottom:0px;
        }
        .price-wrapper {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .price {
				font-size: 25px;
			}
        }
        
    }
    .verified {
        position: absolute;
        top:70px;       
        right:178px;
        color: #a2a0a0dd;
       
            .isverified {
                position: relative;
               &:before {
                content: "";
                // background-image: url('../../images/verified.png');
                width: 15px;
                height: 15px;
                background-size: 100%;
                display: inline-block;
                position: absolute;
                left: -20px;
                top: 2px;
               }
            
            }
        
    }
}


.product-attributes {
    thead {
        background-color: $secondary;
    }

    th {
        width: 50%;
    }

    .highlight {
        background-color: $secondary;
    }
}

.product-table-head{
    border-bottom: 1px solid grey;
}
.productTableRow{
    white-space: nowrap;
}
.product-additional-info{
    font-weight:bolder;
}
.general-info{
    border: 1px solid lightgray;
}
.general-info-label{
    background-color: #F8F8F8;
    border: 1px solid #E5E4E2;
    font-weight: bold;
}
.detail-preview{
    width: 90px;
    height: 90px;
    font-weight: normal;
}
.image-preview-product{
    width: 80px;
    height: 80px;
}
#instruction{
    color:$primary;
    font-weight:bold;
    text-transform: uppercase;
}
.breadcurmb{
    .normalSize{
        color:$primary
    }
}
.example {
    width: 400px;
    height: 200px;
    overflow-y: scroll; /* Add the ability to scroll */
  }
  .box{
      border-color: $primary;
      .col-sm-12{
          color:#3b8c9c;
          font-weight: 900;
      }
  }
  .storage{
      border-color: $primary;
      .card-header{
        height:40px;
        color:$primary;
        font-weight: bold;
      }
      .col-sm-12{
          font-weight: normal;
      }
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .example::-webkit-scrollbar {
      display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .example {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .product-details-tabs {
      margin: 2rem;
      .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: $pallet2;
        font-weight: bold;
   
        
        }
     
    
      .tab-content {
          margin: 1rem;
      }
  }


  .zipcode-available{
    color:green;
    font-weight: bold
  }
  .zipcode-nonavailable{
    color: #ef1d3c;
    font-weight: bold
   }
   .estd-delivery{
       color:$primary
   }

   @media  screen and (max-width:600px) {
    .detail-preview{
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        margin-left: 0%;
    }
    .product-details-tabs{
        margin:0rem !important;
    }
    #size{
        padding-left: 15px !important;
    }
    
   }

@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/utilities";