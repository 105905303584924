.custom-pointer{
    cursor: pointer;
}

.none-style{
    text-decoration: none !important;
}

dp-date-picker.dp-material .dp-picker-input {
    font-size: 1rem !important; 
    outline: none !important;
    border: none !important;
}

.contact-active.active{
    color: $menu-underline-color !important;
}

.contact-active:hover{
    color : $primary !important;
  }

