.home-breadcrumb:hover{
    cursor: pointer;
}


.cart-list{
    margin: 10px;
    height: 80px;
    width: 100%;
    border: none;
    background-color: $white;
}

.count{
    float: right;
    .plus
    {
        cursor: pointer;   
    }

    .minus
    {
        cursor: pointer;
    }
    .numbers
    {
        width: 15px;
        height: 10px;
        font-weight: bolder;
    }
}
.item-name{
    text-align: center;
}

.item-image{
    width: 100px !important;
    height: 80px !important;
    border-radius: 10% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}
.cost{
    padding-top: 10px;
    text-align: center;
    font-weight:bolder;
    float: right;
    font-size: large;
}

.close{
    
    cursor: pointer;
}
.cart-table{
    margin-top: 40px;
    background-color: $white;
    text-align: center;
    width: 100%;
    border: none;
    th
    {
        
        border: none;
    }
    
    td{
        padding-bottom: 2px;
        vertical-align: middle;
        align-items: center;
        border: none;
    }
    }

.cart-table-container{
    .table{
        th{
            border:none;
        }
    }
}      

    .submit-action
    {
        background-color: $red;
        border-radius: 25px;
        
        margin-left: auto;
        color: $white;
    }

.add-cart1{
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    color: $white;
    border-radius: 5px;
    background-color: $primary !important;
    width:90px;
}
.inq-amt-st{
    padding-right: 5px !important;
    padding-left: 5px !important;
    .plus:hover
        {
            cursor: pointer;
            
        }
        .minus:hover
        {
            cursor: pointer;

        }
}

.total-price{
    text-align: end;
}

@media screen and (max-width:600px) {
    .cost{
        position: relative;
        left: -2%;
        top: -50px;
    }
    .total-price{
        text-align: center;
    }
    .cost-deera{
        position: relative;
        top:80px;
        font-weight: 700;
        float:right;
    }
    .cost-price{
        position: relative;
        top:30px;
        font-weight: 700;
        float:right;
    }
    .trash-position{
		position:relative;
		top:-30px;
        left:0%
	}
    .trash-position-bh{
		position:relative;
		top:-10px;
        left:0%
	}
    .trash-position-sc{
		position:absolute;
		top: -5px;
        left:75%
	}
    .cost-trash-posi{
        position: relative;
        left:-2%;
    }
    .cost-trash-posi-sc{
        position: relative;
        width: 25% !important;
        left:-2%;
    }
    
}
@media screen and (min-width:390px) and (max-width:600px) {
    .trash-position{
        position: relative;
        top: 0px;
        left: 50%;
    }
    .trash-position-bh{
        position: relative;
        top: 0px;
        left: -10%;
    }
}
